// authActions.js

import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from 'firebase/auth';
import router from '../../router/index';
import { auth} from '../../lib/firebase';

export const login = async ({dispatch}, payload) => {
  signInWithEmailAndPassword(auth, payload.email, payload.password)
    .catch((error) => {
      console.log(error.message);
    })
    .then(() => {
      dispatch('fetchUserProfile');
      router.push('/').catch(err => console.log(err));
    })
};

export const logout = async ({dispatch}) => {
  await signOut(auth);
  dispatch('fetchUserProfile');
  router.push('/login');
};

export const resetCurrentTimeout = async({dispatch, state}) => {
    if (state.userProfile && state.userProfile.uid) {
      clearTimeout(state.currentTimeout);
      state.currentTimeout = setTimeout(() => {
        dispatch("logout");
      }, 2*60*60*1000);
    }
  }

  export const resetPassword = async (_, email) => {

    sendPasswordResetEmail(auth, email);
    router.push('/reset');
  }

  export const fetchUserProfile = async({commit}) => {
    commit('setUserProfile', (auth.currentUser && auth.currentUser.uid) ? auth.currentUser : null);
  }