import Vue from "vue";
import Vuex from "vuex";
import * as authActions from "./modules/authActions";
import * as analysisActions from "./modules/analysisActions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {},
    loadingDocs: false,
    savingDocs: false,
    savingQueryDocs: false,
    loadingQueries: false,
    loadingQueryDocs: false,
    loadingQuery: false,
    docs: [],
    totalDocs: 0,
    totalScreenedDocs: 0,
    queries: [],
    currentQuery: {},
    queryDocs: [],
    currentWeekLastUpdate: null,
    currentTimeout: null,
    pubmedQuery: "",
    lastVisibleDoc: null,
    loadingSummary: false,
    summary: "",
  },
  mutations: {
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },
    setDocs(state, payload) {
      state.docs = payload;
    },
    setLoadingDocs(state, payload) {
      state.loadingDocs = payload;
    },
    setSavingDocs(state, payload) {
      state.savingDocs = payload;
    },
    setSavingQueryDocs(state, payload) {
      state.savingQueryDocs = payload;
    },
    setCurrentWeekLastUpdate(state, payload) {
      state.currentWeekLastUpdate = payload;
    },
    setQueries(state, payload) {
      state.queries = payload;
    },
    setQueryDocs(state, payload) {
      state.queryDocs = payload;
    },
    setLoadingQueries(state, payload) {
      state.loadingQueries = payload;
    },
    setLoadingQueryDocs(state, payload) {
      state.loadingQueryDocs = payload;
    },
    setCurrentQuery(state, payload) {
      state.currentQuery = payload;
    },
    setPubmedQuery(state, payload) {
      state.pubmedQuery = payload;
    },
    setLoadingQuery(state, payload) {
      state.loadingQuery = payload;
    },
    setTotalDocs(state, payload) {
      state.totalDocs = payload;
    },
    setLastVisibleDoc(state, payload) {
      state.lastVisibleDoc = payload;
    },
    setTotalScreenedDocs(state, payload) {
      state.totalScreenedDocs = payload;
    },
    setLoadingSummary(state, payload) {
      state.loadingSummary = payload;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
  },
  getters: {
    isLoggedIn: (state) => Boolean(state.userProfile && state.userProfile.uid),
    getPubmedQuery(state) {
      return state.pubmedQuery;
    },
    getQueryDocs(state) {
      return state.queryDocs;
    }
  },
  actions: {
    ...authActions,
    ...analysisActions,
  },
});
