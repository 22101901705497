import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0B2560", // '#FEF1E6',
        secondary: "#EBE7E2", // "F5CA52", //"#ECF3FD",
        accent: "#E8E8EE", //"#F9D5A7",
        error: "#F0887D",
        info: "#F5CA52", //"#90AACB",
        success: "#ffffff",
        warning: "#4F4F4F",
        anchor: "#0B2560", //"#90AACB",
        "v-progress-linear": "#90AACB",
      },
    },
  },
});

export default vuetify;
