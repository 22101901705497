import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
//browserLocalPersistence
import { getFirestore, collection } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCtgkeSp0kbl1id6rMra0jP6Kb-7yxTT78",
  authDomain: "eh-healthbase.firebaseapp.com",
  projectId: "eh-healthbase",
  storageBucket: "eh-healthbase.appspot.com",
  messagingSenderId: "436564203920",
  appId: "1:436564203920:web:bef90a8c2fd8d8ecedfb15"
};

initializeApp(firebaseConfig);

const auth = getAuth();
setPersistence(auth, browserSessionPersistence); 
//browserLocalPersistence

const db = getFirestore();

const researchCollection = collection(db, 'research');
const queriesCollection = collection(db, 'queries');

export { auth, researchCollection, queriesCollection }; 