import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../lib/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Queries', 
    component: () => import('../views/Queries.vue'), 
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/queryViewer',
    name: 'QueryViewer',
    component: () => import('../views/QueryViewer.vue'), 
    meta: {
      requiresAuth: true
    },
    props(route) {
      return {  qid: route.query.qid }
    }
  },
  {
    path: '/history',
    name: 'History', 
    component: () => import('../views/History.vue'), 
    meta: {
      requiresAuth: true
    },
  }, 
  {
    path: '/login',
    name: 'Login', 
    component: () => import('../views/Login.vue')
  }, 
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  }, 
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.currentUser && auth.currentUser.uid) {
      next();
    } else {
      next({
        path: 'login',
      });
    }
  } else {
    next();
  }
});

export default router
