<template>
  <div id="nav">
    <v-app-bar dense elevation="8" prominent color="primary">
      <div class="my-auto">
        <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-md-none">
        </v-app-bar-nav-icon>
      </div>

      <v-app-bar-title class="text-no-wrap ml-5">
        <v-img>
          <svg
            class="owl"
            width="40.852px"
            height="47.914px"
            viewBox="0 0 40.852 47.914"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="M28.7,0c-3.097,0-6.018,1.157-8.273,3.265C18.171,1.157,15.25,0,12.153,0H0l2.587,2.598h9.566c2.466,0,4.79,0.931,6.573,2.626l1.7,2.149l1.7-2.149c1.783-1.695,4.107-2.626,6.574-2.626h9.565L40.852,0H28.7z"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FDC82F"
              d="M10.456,12.482c0,1.485,1.2,2.688,2.68,2.688c1.477,0,2.678-1.203,2.678-2.688c0-1.484-1.201-2.688-2.678-2.688C11.655,9.795,10.456,10.998,10.456,12.482 M12.242,12.482c0-0.495,0.398-0.896,0.893-0.896c0.492,0,0.891,0.401,0.891,0.896s-0.399,0.896-0.891,0.896C12.641,13.378,12.242,12.978,12.242,12.482"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="M19.88,12.402c0-3.717-3.012-6.741-6.714-6.741H6.452l2.587,2.598h4.127c2.276,0,4.127,1.859,4.127,4.144s-1.851,4.144-4.127,4.144s-4.127-1.859-4.127-4.144H6.452c0,3.717,3.012,6.74,6.714,6.74c1.512,0,2.908-0.505,4.031-1.354l2.683,2.693V12.402z"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FDC82F"
              d="M27.717,9.795c-1.478,0-2.677,1.203-2.677,2.688s1.2,2.688,2.677,2.688c1.479,0,2.679-1.204,2.679-2.688S29.197,9.795,27.717,9.795 M27.717,13.378c-0.493,0-0.891-0.4-0.891-0.896s0.398-0.896,0.891-0.896c0.494,0,0.893,0.401,0.893,0.896S28.211,13.378,27.717,13.378"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="M27.687,16.546c-2.276,0-4.127-1.859-4.127-4.144c0-2.285,1.852-4.144,4.127-4.144h4.127L34.4,5.661h-6.714c-3.703,0-6.715,3.024-6.715,6.741v8.079l2.683-2.693c1.124,0.85,2.52,1.354,4.032,1.354c3.703,0,6.714-3.023,6.714-6.74h-2.587C31.813,14.687,29.962,16.546,27.687,16.546"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="M25.003,42.413c-1.174-0.365-2.443-0.855-3.703-1.525c-5.521-2.937-8.319-7.872-8.319-14.672v-1.2v-2.732h-2.722v3.933c0,18.404,18.253,19.854,19.769,19.943C28.097,45.124,26.417,43.87,25.003,42.413"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#FFFFFF"
              d="M37.594,25.546c-2.095-2.104-4.881-3.263-7.845-3.263h-8.777v5.683c0,19.202,19.872,19.948,19.872,19.948V33.422C40.844,30.446,39.689,27.649,37.594,25.546z M38.122,44.785c-1.685-0.345-3.915-0.981-6.108-2.147c-5.521-2.937-8.319-7.872-8.319-14.672v-2.95h6.055c2.237,0,4.339,0.874,5.92,2.463c1.582,1.587,2.453,3.697,2.453,5.943V44.785z"
            ></path>
          </svg>
        </v-img>
      </v-app-bar-title>

      <v-tabs class="d-none d-md-block" color="success" fixed-tabs centered>
        <v-tabs-slider color="info"></v-tabs-slider>

        <v-tab class="custom-tab" v-if="isLoggedIn" to="/"> Queries </v-tab>
        <v-tab class="custom-tab" v-if="isLoggedIn" to="/history">
          My History
        </v-tab>

        <v-tab class="custom-tab" v-if="isLoggedIn" to="/logout">
          Logout
        </v-tab>

        <v-tab class="custom-tab" v-else to="/login"> Login </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary color="secondary">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-feature-search</v-icon>
            </v-list-item-icon>
            <router-link to="/" class="anchor"> Queries </router-link>
          </v-list-item>

          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-clipboard-text-clock</v-icon>
            </v-list-item-icon>
            <router-link to="/history" class="anchor"> History </router-link>
          </v-list-item>

          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <router-link to="/logout" class="anchor"> Logout </router-link>
          </v-list-item>

          <v-list-item v-else>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <router-link to="/login" class="anchor"> Login </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>
.custom-tab {
  color: var(--v-secondary-base) !important;
}
.custom-tab.v-tab--active {
  color: var(--v-info-base) !important;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
};
</script>
